<template>
  <app-loading-container
    class="w-full px-4 py-12 mb-32 lg:py-16 sm:px-10"
    :loading="loading"
    :loaded="loaded"
    :error-loading="errorLoading"
    :show-loader="true"
  >
    <div class="font-bold text-gray-type-2">
      Generate Trip Report for your staff
    </div>
    <div class="items-center mt-6 space-y-4 lg:space-y-0 lg:space-x-4 lg:flex">
      <div class="p-4 bg-white border rounded border-gray-type-7">
        <div class="flex items-center justify-between">
          <div class="inline-flex">
            <span class="text-xs font-medium text-gray-type-2"
              >Start date:</span
            >
            <datepicker
              v-model="params.start_date"
              ref="startDatePicker"
              class="w-20 ml-2 text-xs font-medium border-0 focus:outline-none text-gray-type-8"
              :config="{
                wrap: true,
                altFormat: 'j M Y',
                altInput: true,
                dateFormat: 'Y-m-d',
              }"
            ></datepicker>
          </div>
          <button
            class="ml-8 focus:outline-none"
            @click="$refs.startDatePicker.fp.open()"
          >
            <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
                  <g fill="none" fill-rule="evenodd">
                    <path d="M0 0h20v20H0z" />
                    <rect
                      stroke="#737D78"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      x="3.125"
                      y="3.125"
                      width="13.75"
                      height="13.75"
                      rx="3"
                    />
                    <path
                      stroke="#737D78"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M13.773 1.875v2.5M6.273 1.875v2.5M3.125 6.898h13.75"
                    />
                  </g>
                </svg>
          </button>
        </div>
      </div>
      <div class="p-4 bg-white border rounded border-gray-type-7">
        <div class="flex items-center justify-between">
          <div class="inline-flex">
            <span class="text-xs font-medium text-gray-type-2">End date:</span>
            <datepicker
              v-model="params.end_date"
              ref="endDatePicker"
              class="w-20 ml-2 text-xs font-medium border-0 focus:outline-none text-gray-type-8"
              :config="{
                wrap: true,
                altFormat: 'j M Y',
                altInput: true,
                dateFormat: 'Y-m-d',
              }"
            ></datepicker>
          </div>
          <button
            class="ml-8 focus:outline-none"
            @click="$refs.endDatePicker.fp.open()"
          >
            <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
                  <g fill="none" fill-rule="evenodd">
                    <path d="M0 0h20v20H0z" />
                    <rect
                      stroke="#737D78"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      x="3.125"
                      y="3.125"
                      width="13.75"
                      height="13.75"
                      rx="3"
                    />
                    <path
                      stroke="#737D78"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M13.773 1.875v2.5M6.273 1.875v2.5M3.125 6.898h13.75"
                    />
                  </g>
                </svg>
          </button>
        </div>
      </div>
      <app-button
        class="w-auto px-10 py-3 font-bold text-center text-white rounded bg-green-type-1"
        @click="generateReport"
        :loading="processingReport"
      >
        Generate Report
      </app-button>

      <app-button
          class="w-auto px-10 py-3 font-bold text-center text-gray-type-15 rounded border border-gray-type-15"
          @click="openUtilityReportPreview">
        Preview Report
      </app-button>
    </div>
    <div class="mt-10">
      <div class="ml-8 font-bold text-gray-type-2">Today’s trips</div>
      <app-table
          :items="reports"
          :fields="reportFields"
          :error-loading="errorLoading"
          :extra-options="tableDataOptions"
          :use-pagination="true"
          @page-change="loadReportData"
          @size-change="loadReportData(1, $event)"
          class="mt-6">
        <template v-slot:download_link="{ item }">
          <a
            :href="item.url"
            class="text-xs font-medium text-green-type-1"
            target="_blank"
            rel="noopener noreferrer"
          >
            Download
          </a>
        </template>
      </app-table>
    </div>
  </app-loading-container>
</template>

<script>
import { mapGetters } from "vuex";
import AppTable from "@/components/AppTable";
import moment from "moment";

export default {
  components: {
    AppTable,
  },
  data() {
    return {
      loading: false,
      loaded: false,
      errorLoading: false,
      reports: [],
      reportFields: [
        { key: "name", label: "Name" },
        { key: "date", label: "Date" },
        { key: "download_link", label: "Download Link" },
      ],
      params: {
        end_date: new Date(),
        start_date: `${new Date().getFullYear()}-${
          new Date().getMonth() + 1
        }-1`,
      },
      processingReport: false,
      errorGeneratingReport: false,
      tableDataOptions: {
        itemsPerPage: 25,
        serverSide: true,
        totalSize:0
      },
      currentPage: 1,
    };
  },
  created() {
    this.fetchReports();
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    fetchReports(page = 1, showLoader = true) {
      console.log(showLoader)
      if(showLoader){
        this.loading = true;
        this.errorLoading = this.loaded = false;
      }
      this.$http
        .get(`/corporates/${this.user.id}/trip-reports?metadata=true&limit=${this.tableDataOptions.itemsPerPage}&page=${page}`)
        .then((res) => {
          console.log(res.data?.data);
          this.reports = res.data?.data || [];
          this.tableDataOptions.totalSize = res.data?.metadata?.total || 0;
          this.loaded = true;
        })
        .catch(() => {
          this.errorLoading = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    forceDownload(url) {
      const link = document.createElement("a");
      link.href = url;

      link.setAttribute("target", "_blank");

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    generateReport() {
      this.processingReport = true;

      this.errorGeneratingReport = false;
      let startDate = moment(this.params.start_date);
      let endDate = moment(this.params.end_date);

      if(Object.prototype.hasOwnProperty.call(this.params, "start_date") && Object.prototype.hasOwnProperty.call(this.params, "end_date")) {
        if(startDate.isAfter(endDate)) {
          this.$toast.error(
            "Invalid date range error"
          );
          this.processingReport = false;
          return false;
        }
      }

      this.$http
        .post(`/corporates/${this.user.id}/trip-reports`, {
          ...this.params
        })
        .then((res) => {
          this.forceDownload(res.data.url);
          
          this.fetchReports();
        })
        .catch(() => {
          this.errorGeneratingReport = true;
        })
        .finally(() => {
          this.processingReport = false;
        });
    },
    openUtilityReportPreview() {
      let {end_date, start_date} = this.params;
      if(end_date instanceof Date){
        end_date = moment(end_date).format('YYYY-MM-DD');
      }
      this.$router.push({path: '/reports/preview', query: {start: start_date, end: `${end_date}`}});
    },
    loadReportData(page, size = null) {
      if(size) {
        this.tableDataOptions.itemsPerPage = size;
      }
      this.fetchReports(page, false);
    }
  },
};
</script>